// extracted by mini-css-extract-plugin
export var succesStoriesCard = "fK_NF";
export var succesStoriesCardRow = "fK_NQ";
export var succesStoriesCard__bottomWrapper = "fK_NL";
export var succesStoriesCard__category = "fK_NN";
export var succesStoriesCard__categoryWrapper = "fK_NM";
export var succesStoriesCard__description = "fK_NK";
export var succesStoriesCard__image = "fK_NH";
export var succesStoriesCard__locaation = "fK_NP";
export var succesStoriesCard__logosWrapper = "fK_NJ";
export var succesStoriesCard__title = "fK_NG";