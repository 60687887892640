// extracted by mini-css-extract-plugin
export var homeWebDevProcess = "cB_rQ";
export var homeWebDevProcess__between = "cB_rR";
export var homeWebDevProcess__contentFive = "cB_r0";
export var homeWebDevProcess__contentFour = "cB_rZ";
export var homeWebDevProcess__contentOne = "cB_rW";
export var homeWebDevProcess__contentThree = "cB_rY";
export var homeWebDevProcess__contentTwo = "cB_rX";
export var homeWebDevProcess__description = "cB_rV";
export var homeWebDevProcess__image = "cB_rS";
export var homeWebDevProcess__title = "cB_rT";