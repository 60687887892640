// extracted by mini-css-extract-plugin
export var companyInfo__card = "cq_rj";
export var companyInfo__cardText = "cq_rl";
export var companyInfo__cardTitle = "cq_rk";
export var companyInfo__description = "cq_rh";
export var companyInfo__flex = "cq_rr";
export var companyInfo__head = "cq_rq";
export var companyInfo__headSm = "cq_rp";
export var companyInfo__title = "cq_rg";
export var companyInfo__topWrapper = "cq_rn";
export var companyInfo__wrapper = "cq_rm";