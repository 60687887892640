// extracted by mini-css-extract-plugin
export var achievementsSlider__badge_1 = "fz_Ml";
export var achievementsSlider__badge_10 = "fz_Mw";
export var achievementsSlider__badge_11 = "fz_Mx";
export var achievementsSlider__badge_12 = "fz_My";
export var achievementsSlider__badge_13 = "fz_Mz";
export var achievementsSlider__badge_14 = "fz_MB";
export var achievementsSlider__badge_15 = "fz_MC";
export var achievementsSlider__badge_16 = "fz_MD";
export var achievementsSlider__badge_17 = "fz_MF";
export var achievementsSlider__badge_18 = "fz_MG";
export var achievementsSlider__badge_19 = "fz_MH";
export var achievementsSlider__badge_2 = "fz_Mm";
export var achievementsSlider__badge_3 = "fz_Mn";
export var achievementsSlider__badge_4 = "fz_Mp";
export var achievementsSlider__badge_5 = "fz_Mq";
export var achievementsSlider__badge_6 = "fz_Mr";
export var achievementsSlider__badge_7 = "fz_Ms";
export var achievementsSlider__badge_8 = "fz_Mt";
export var achievementsSlider__badge_9 = "fz_Mv";
export var achievementsSlider__logo = "fz_Mk";
export var achievementsSlider__swiperSliderWrapper = "fz_Mj";